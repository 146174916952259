@import url('https://fonts.googleapis.com/css2?family=Kanit&display=swap');

/* .strokeText {
    text-shadow: -1px -1px 0 purple, 1px -1px 0 purple, -1px 1px 0 purple, 1px 1px 0 purple;
} */

.strokeText {
    /* -webkit-text-stroke-width: 1px;
		-webkit-text-stroke-color: purple;
        color: white;
		text-shadow: none; */
        color: purple;
        -webkit-text-fill-color: transparent; /* Will override color (regardless of order) */
        -webkit-text-stroke: 1px purple;
  /* text-shadow: -1px 0 purple, 1px -1px 0 purple, -1px 1px 0 purple, 1px 1px 0 purple; */
}

.strokeTextBlue {
    /* -webkit-text-stroke-width: 1px;
		-webkit-text-stroke-color: purple;
        color: white;
		text-shadow: none; */
        color: #0B4697;
        -webkit-text-fill-color: transparent; /* Will override color (regardless of order) */
        -webkit-text-stroke: 1px #0B4697;
  /* text-shadow: -1px 0 purple, 1px -1px 0 purple, -1px 1px 0 purple, 1px 1px 0 purple; */
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  -webkit-appearance:none;
}